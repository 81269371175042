import React from 'react'
import { Link } from 'gatsby'

const Card = ({ oggetto, externalLink = false }) => {
  const aClassName =
    'flex flex-col min-h-full h-full p-6 border border-blue-200 rounded-lg hover:shadow-lg hover:bg-blue-100 transition duration-300 ease-in-out bg-white'

  const renderContent = (oggetto) => {
    return (
      <>
        {oggetto.preTitolo && (
          <div className="mb-2 text-blue-500 text-xs uppercase">
            <span className="text-gls-yellow font-bold mr-2">{'//'}</span>{oggetto.preTitolo}
          </div>
        )}
        <h2 className="mb-4 text-lg font-semibold leading-tight text-gls-blue-900">
          {oggetto.nome}
        </h2>
        <p className="text-sm text-gray-600">{oggetto.descrizione}</p>
      </>
    )
  }

  return (
    <div className="px-4 mb-6 md:mb-8 w-full md:w-1/2 lg:w-1/3">
      {externalLink ? (
        <a
          href={oggetto.linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={aClassName}
        >
          {renderContent(oggetto)}
        </a>
      ) : (
        <Link to={oggetto.linkUrl} className={aClassName}>
          {renderContent(oggetto)}
        </Link>
      )}
    </div>
  )
}

export default Card
