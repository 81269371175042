import React, { Fragment } from 'react'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import TopImage from '../../components/TopImage'
import Card from '../../components/Card'

import ServiziAccessoriImg from '../../images/accessori.jpg'

const paginaServizio = {
  titolo: 'Servizi Accessori',
  imgUrl: ServiziAccessoriImg,
  servizi: [
    {
      id: 1,
      nome: 'Express 12',
      descrizione:
        'Quando vuoi che le tue spedizioni urgenti arrivino a destinazione entro le ore 12.00.',
      linkUrl: '/servizi/accessori/express-12',
    },
    {
      id: 2,
      nome: 'Exchange',
      descrizione:
        'Se devi effettuare una consegna con contestuale ritiro di merci e/o documenti presso lo stesso indirizzo.',
      linkUrl: '/servizi/accessori/exchange',
    },
    {
      id: 3,
      nome: 'Document Return',
      descrizione:
        'Consegniamo le tue spedizioni e pensiamo noi a restituirti i documenti di cui hai bisogno debitamente compilati e firmati dal destinatario.',
      linkUrl: '/servizi/accessori/document-return',
    },
    {
      id: 4,
      nome: 'Info',
      descrizione:
        'Per avere sotto controllo il flusso delle tue spedizioni via e-mail o sms.',
      linkUrl: '/servizi/accessori/info',
    },
    {
      id: 5,
      nome: 'Pick & Return',
      descrizione:
        'Per richiedere il ritiro di pacchi fino a 50 kg in tutta Europa e senza limiti di peso in tutta Italia.',
      linkUrl: '/servizi/accessori/pick-return',
    },
    {
      id: 6,
      nome: 'POD',
      descrizione:
        'Per essere sicuro che il tuo pacco sia stato recapitato correttamente, visualizza online la prova di consegna della tua spedizione.',
      linkUrl: '/servizi/accessori/pod',
    },
    {
      id: 7,
      nome: 'Additional Insurance',
      descrizione:
        'Se vuoi estendere la copertura assicurativa prevista per legge, con GLS puoi scegliere fra tre diverse opzioni aggiuntive.',
      linkUrl: '/servizi/accessori/additional-insurance',
    },
    {
      id: 8,
      nome: 'Pick & Ship',
      descrizione:
        "Per richiedere la consegna presso domicili diversi dal tuo, in Italia o all'estero.",
      linkUrl: '/servizi/accessori/pick-ship',
    },
    {
      id: 9,
      nome: 'Saturday',
      descrizione: 'Le tue spedizioni ti verranno consegnate anche il sabato.',
      linkUrl: '/servizi/accessori/saturday',
    },
    {
      id: 10,
      nome: 'Destination Pay',
      descrizione:
        'Puoi far pagare la spedizione al destinatario nel momento in cui la riceve.',
      linkUrl: '/servizi/accessori/destination-pay',
    },
    {
      id: 11,
      nome: 'Depot Pickup',
      descrizione:
        'Il destinatario può ritirare la spedizione fino al terzo giorno lavorativo successivo all’arrivo presso la Sede più vicina.',
      linkUrl: '/servizi/accessori/depot-pickup',
    },
    {
      id: 12,
      nome: 'COD',
      descrizione:
        'Servizio accessorio contrassegno che consiste nell’incasso per conto del mittente di un determinato importo quale corrispettivo della merce spedita.',
      linkUrl: '/servizi/accessori/cod',
    },
    {
      id: 13,
      nome: 'eCom',
      descrizione: "Servizio dedicato per l'e-commerce.",
      linkUrl: '/servizi/accessori/e-com',
    },
    {
      id: 14,
      nome: 'GDO',
      descrizione:
        'Servizio per le consegne presso la grande distribuzione organizzata (GDO) o i centri logistici.',
      linkUrl: '/servizi/accessori/gdo',
    },
    {
      id: 15,
      nome: 'Sprinters',
      descrizione: 'Soluzioni per personalizzare al massimo le tue spedizioni.',
      linkUrl: '/servizi/accessori/sprinters',
    },
    {
      id: 16,
      nome: 'Ident PIN',
      descrizione:
        'Solo il destinatario designato può ricevere la spedizione inserendo sul palmare un codice PIN di sblocco fornitogli preventivamente dal mittente.',
      linkUrl: '/servizi/accessori/ident-pin',
    },
    {
      id: 17,
      nome: 'Food Service',
      descrizione:
        'Food Service è il servizio dedicato al trasporto della merce alimentare non deperibile trasportabile a temperatura ambiente.',
      linkUrl: '/servizi/accessori/food-service',
    },
    {
      id: 18,
      nome: 'Return Service',
      descrizione:
        'Return Service si rivolge a tutti coloro che vendono online e hanno la necessità di gestire i propri resi in maniera semplice e veloce.',
      linkUrl: '/servizi/accessori/return-service',
    },
  ],
}

const ServiziAccessori = () => {
  return (
    <Layout>
      <SEO
        title="Servizi accessori"
        description="Mediterranea Express - Corriere GLS Sicilia - Tutti i servizi accessori"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg lg:shadow-xl">
        <TopImage imgUrl={paginaServizio.imgUrl} />
        <div className="flex flex-wrap py-12 px-6">
          {paginaServizio.servizi.map((servizio) => {
            return (
              <Fragment key={servizio.id}>
                <Card oggetto={servizio} />
              </Fragment>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default ServiziAccessori
